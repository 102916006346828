@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.siteHeader {
  display: flex;
  align-items: center;
  background: var(--theme-color-secondary);
  color: var(--white);
  position: sticky;
  height: 52px;
  top: 0;
  z-index: 10;
  @include transition(0.2s all linear 0.1s);

  [data-light-mode='false'] & {
    background: #121110;
  }
  @media (min-width: 767px) {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
  }

  &.fixedAnimation {
    position: sticky;
    top: -58px;
    transform: translateY(-100%);
  }

  &.fixed {
    position: sticky;
    top: 0;
    transform: translateY(0%);
    width: 100%;
    background: $theme-color;
  }

  .navbar {
    padding: 12px 0;
    font-size: 12px;
    line-height: 20px;
    z-index: 5;
    position: sticky;
    top: 0;

    .menuIcon {
      width: 25px;
      height: 2px;
      background-color: var(--white);
      margin: 8px 4px;
    }

    .nav {
      height: 36px;
      .userDropdown > button,
      .navLink {
        margin-left: 16px;
        color: var(--font-color);
        font-weight: 700;
        border: none;
        background-color: transparent;

        &:focus {
          @include box-shadow(none);
        }

        &:hover {
          color: #fff;
        }

        &.fillBtn,
        &.outlineBtn {
          border-radius: 2em;
        }

        &.fillBtn {
          padding: 5px 12px;
          background: #fff;
          color: $theme-color;
        }

        &.outlineBtn {
          padding: 4px 12px;
          color: #fff;
          border: 2px solid #fff;

          svg {
            margin: 0 4px 0 -2px;
          }
        }
      }
    }

    .userDropdown {
      cursor: pointer;
      padding: 20px 15px 20px 15px;

      & > button {
        margin-left: 0px !important;
      }
    }

    .separator {
      margin: 0 18px;
      width: 1px;
      height: 38px;
      display: block;
      background: var(--border-color);
    }

    .userDropdown {
      > button {
        display: block;
        padding: 0;
        border-radius: 50%;
      }

      .dropdownMenu {
        min-width: 190px;
      }
    }

    .profileInfo {
      margin-bottom: 7px;
      padding-bottom: 9px;
      border-bottom: 1px solid $border-light2;
      cursor: pointer;
    }
  }
}

.logo {
  width: 235px;
  flex-shrink: 0;
}

.modeSwitch {
  label {
    color: #fff;
    margin-right: 8px;
  }
}

.mobileSportsHeader {
  display: flex;
  align-items: center;
  background: var(--theme-color-secondary);
  color: var(--white);
  // height: 52px;
  padding: 10px 2px;
  top: 0;
  z-index: 10;
  @include transition(0.2s all linear 0.1s);
  [data-light-mode='false'] & {
    background: #121110;
  }
  // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
  overflow: auto;
  // max-height: 200px;
}

.userDropdown {
  .dropdownVerify {
    button {
      width: auto;
      border: 1px solid;
    }
  }

  .active {
    color: $theme-color;
  }
}

.userProfileImage {
  padding: 0 !important;
  width: 21px;
}

.userToggle {
  color: var(--white) !important;
  &:hover {
    color: var(--theme-color-light) !important;
  }
  &::after {
    display: none;
  }
}

.menuItem {
  cursor: pointer;
  margin: 0 14px;
  padding: 0px;
  font-size: 14px;
  line-height: 20px;
  display: inline-flex;
  color: var(--white);
  border-radius: 0px;
  letter-spacing: 0.4px;
  &:hover {
    color: var(--font-color-dark);
  }
}

.mobileMenuItem {
  cursor: pointer;
  margin: 0 12px;
  padding: 0px;
  font-size: 14px;
  line-height: 20px;
  display: inline-flex;
  color: var(--white);
  border-radius: 0px;
  letter-spacing: 0.4px;
  &:hover {
    color: var(--font-color-dark);
  }
}

.sgmedia {
  margin-right: 58px;
  padding-right: 10px;
  background-color: white;
  padding-left: 5px;
  position: relative;
  min-height: 32px;
  &::before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: white;
    top: 0;
    right: 100%;
    width: 100vw;
  }
  &::after {
    content: '';
    width: 0px;
    position: absolute;
    height: 0px;
    left: 100%;
    border-style: solid;
    border-width: 32px 0 0 15px;
    border-color: transparent transparent transparent white;
    transform: rotate(0deg);
  }
  // border-left: 1px solid #DEDEDE80;
  span {
    color: #9e9e9e;
    font-size: 12px;
    font-weight: 700;
  }
  @media (max-width: 767px) {
    padding-left: 13px;
    // width: 40px;
  }
  @media (max-width: 350px) {
    display: none;
  }
}

.siteSecondaryHeader {
  background-color: var(--theme-color-header);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 32px;
}
.selectedTriangle {
  width: 0px;
  height: 0px;
  bottom: 4px;
  border-style: solid;
  border-width: 0 6px 9px 6px;
  border-color: transparent transparent var(--theme-color-header) transparent;
  transform: rotate(0deg);
}

.selectedTriangleMobile {
  width: 0px;
  height: 0px;
  bottom: -10px;
  border-style: solid;
  border-width: 0 6px 9px 6px;
  border-color: transparent transparent var(--theme-color-header) transparent;
  transform: rotate(0deg);
}

.menuContainer {
  margin-left: 65px;
}

.sgMobilemedia {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #9e9e9e;
    font-size: 12px;
    font-weight: 700;
  }

  @media (max-width: 767px) {
    padding: 0px 8px;
  }

  @media (max-width: 350px) {
    display: none;
  }
}

.sgMediaContainer {
  background-color: white;
  padding: 4px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::before,
  &::after {
    content: '';
    height: 2px;
    background-color: rgba(167,172,180,1);
    flex-grow: 1;
    opacity: 3;
  }
}

.more {
  display: flex;
  align-items: center;
  width: 15px;
  height: 15px;
}

/***** Responsive CSS Start ******/
@media (min-width: 1400px) {
  .userDropdown .dropdownMenu {
    right: 50% !important;
    @include transform(translate(calc(50% + 8px)) !important);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .searchGroup {
    width: 440px;
  }

  .logo {
    width: 235px;
  }

  .siteHeader {
    .navbar {
      .separator {
        margin: 0 10px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .siteHeader {
    .navbar {
      padding: 12px 0;

      .menuIcon {
        width: 23px;
        margin: 5px 4px;
      }

      .nav {
        .navLink {
          margin-left: 9px;
        }
      }

      .separator {
        margin: 0 10px;
        height: 28px;
      }
    }
  }

  .logo {
    width: 235px;
  }

  .langMenu {
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .siteHeader {
    height: 48px;

    .navbar {
      padding: 10px 0;
      min-height: 48px;

      .menuIcon {
        width: 21px;
        margin: 5px 4px;
      }

      .nav {
        .userDropdown {
          padding: 12px;
        }

        .navLink {
          margin-left: 9px;

          &.fillBtn {
            padding: 4px 10px;
          }
        }
      }

      .separator {
        margin: 0 10px;
        height: 26px;
      }
    }
  }

  .logo {
    width: 140px;
  }

  .langMenu {
    margin-left: 9px;
  }
}

@media (max-width: 575px) {
  .siteHeader {
    .navbar {
      .nav {
        .userDropdown {
          padding: 12px 8px;
        }

        .userDropdown > button,
        .navLink {
          margin-left: 4px;
        }
      }
    }
  }

  .langMenu {
    margin-left: 6px;
  }
}

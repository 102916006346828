.install{
    background-color: var(--theme-color-light);
    color: white;
    padding: 8px 16px;
    border-radius: 44px;
    border: none;
    font-size: 16px;
    font-weight: 600;
}

.t1{
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

.t2{
    font-weight: 500;
    font-size: 12px;
    color: #868282;
}